import React, {useEffect, useState} from "react"
import {graphql, Link} from "gatsby"
import Img from "gatsby-image";
import Layout from "../components/layout";
import "./blogTemplate.css"
import globalStyle from "../styles/global.module.css";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import env from "../config/env";
import formStyle from "../pages/form.module.css";
import blogTemplateStyle from "./blogTemplate.module.css"
import addToMailchimp from "gatsby-plugin-mailchimp";

export default function Template({data}) {

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark;
  const [modalShow, setModalShow] = useState(false);
  const [modalFormState, setModalFormValues] = useState({email: "",});
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({ show: false, message: "" });
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccessMessage, setShowSuccesMessage] = useState(false);
  const submitParentingNewsletterUrl = "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=1e75706ef5";

  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => {setModalShow(true)};

  const validateModalForm = () => {
    let isValid = true;

    if (!modalFormState.email) {
      isValid = false;
      setShowEmailErrorMessage({
        show: true,
        message: "Please enter your email address!"
      })
    }

    return isValid
  };

  const saveSubscribedEmailToMailchimp = async () => {

    try {
      const result = await addToMailchimp(modalFormState.email, {}, submitParentingNewsletterUrl);
      const alreadyAMemberError = modalFormState.email + ` is already subscribed to list`
      if (result.result === 'success') {
        setShowSuccesMessage(true);
      } else {
        if (result.result === 'error' && result.msg.includes(alreadyAMemberError)) {
          setShowAlert({
            show: true,
            msg: 'You already subscribed to list!'
          });
        } else {
          setShowAlert({
            show: true,
            msg: result.msg
          });
        }
      }
    }
     catch (err) {
      console.error(err);
      return false;
    }
  };

  const validateAndSaveData = async () => {
    if (validateModalForm()) {
      await saveSubscribedEmailToMailchimp();
    }
  };

  const handleSubscribeFormChange = e => {
    let value = e.target.value;
    setModalFormValues({
      ...modalFormState,
      [e.target.name]: value,
    });
  };

  return (
    <Layout className="site-content"
          title={frontmatter.title}
          path={frontmatter.path}
          description={frontmatter.description}
          image={env.domain + frontmatter.featuredImage.publicURL}
          structuredData={{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://google.com/article"
      },
      "headline": frontmatter.title,
      "image": [env.domain + frontmatter.featuredImage.publicURL],
      "datePublished": frontmatter.date,
      "author": {
        "@type": "Person",
        "name": frontmatter.author
      },
      "publisher": {
        "@type": "Organization",
        "name": "Moms on Maternity",
        "logo": {
          "@type": "ImageObject",
          "url": env.domain + data.logo.publicURL
        }
      },
      "description": frontmatter.description
    }}>
      <div className="blog-post-container">
        <div className="blog-post">
          <div className="post-thumbnail">
              <Img  fluid={frontmatter.featuredImage.childImageSharp.fluid}/>
          </div>
          <div className="blog-post-content">
            <div className="entry-meta">
              <Link to={frontmatter.path}>
                <span >{frontmatter.date}</span>
              </Link>
              <Link className="link-to-page" to={"/author/" + frontmatter.author}>
                <span>{frontmatter.author}</span>
              </Link>
            </div>

            <h1 className="entry-title">{frontmatter.title}</h1>
            <hr></hr>
            <div
                className=""
                dangerouslySetInnerHTML={{ __html: html }}
            />
            <div>
              If you are interested in events and M.O.M ongoings, please subscribe to our parenting
              <a href="#" onClick={() => {handleShowModal(); return;}}> newsletter </a>
              or follow us on <a href="https://www.facebook.com/MomsOnMaternity/" target="_blank"  rel="noreferrer">Facebook</a> or
              <a href="https://www.instagram.com/momsonmaternity/" target="_blank"  rel="noreferrer"> Instagram</a>.
            </div>
            <Row className="justify-content-center aboutFounder">
              <div className="col-md-2 col-sm-2 col-xs-12">
                <h4>WRITTEN BY:</h4>
                <Img className="authorPhoto" fluid={frontmatter.authorImage.childImageSharp.fluid} />
                <div className="authorName">
                  <p className={globalStyle.boldStyle}>{frontmatter.author}</p>
                  <a className={globalStyle.redirectLink} href="/blog/">View All Posts</a>
                </div>
              </div>
              <div className="col-md-9 col-sm-9 col-xs-12">
                <p>{frontmatter.aboutAuthor}</p>
              </div>
            </Row>
            <Form.Row>
              <Col>
                <Modal
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={modalShow}
                  onHide={handleCloseModal}
                  className="subscribeToNewsletterModal"
                  name="subscribeToNewsletterModal"
                  id="subscribeToNewsletterModal"
                >
                  <Modal.Header className={[blogTemplateStyle.modalBackground, blogTemplateStyle.modalHeader].join(' ')}
                                closeButton>
                  </Modal.Header>
                  <Modal.Body className={blogTemplateStyle.modalBackground}>

                    <Form.Row className={[blogTemplateStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                      <Col xs={12} sm={12} md={12}>
                        <h4 className={blogTemplateStyle.modalTitle}>Subscribe to our parenting newsletter!</h4>
                        <Form.Group as={Col} controlId="formEmail"
                                    className={[blogTemplateStyle.registerFormGroup, blogTemplateStyle.formLabel]}>
                          <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email address</Form.Label>
                          <Form.Control
                            size="sm"
                            value={modalFormState.email}
                            name="email"
                            type="email"
                            placeholder="Type your email address"
                            onChange={handleSubscribeFormChange}
                            className={blogTemplateStyle.formInput}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    {showEmailErrorMessage.show &&
                    <Form.Row className="justify-content-md-center">
                      <Col xs={12} sm={10} md={8} >

                        <Alert
                          variant="danger"
                          onClose={() => setShowEmailErrorMessage({ show: false })}
                          dismissible
                        >
                          {showEmailErrorMessage.message}
                        </Alert>
                      </Col>
                    </Form.Row>
                    }

                    {showAlert &&
                    <Form.Row className="justify-content-md-center">
                      <Col xs={12} sm={10} md={8} >

                        <Alert
                          variant="danger"
                          onClose={() => setShowAlert(false)}
                          dismissible
                        >
                          {showAlert.msg}
                        </Alert>
                      </Col>
                    </Form.Row>
                    }

                    {
                    <Row className="justify-content-md-center">
                      <Col className="text-center">
                        <Button
                          type="button"
                          className={[
                            globalStyle.btnPrimary,
                            blogTemplateStyle.confirmButton
                          ]}
                          onClick={validateAndSaveData}
                        >
                          Confirm
                        </Button>
                      </Col>
                    </Row>
                    }

                    {showSuccessMessage &&
                    <Form.Row className="justify-content-md-center">
                      <Col xs={12} sm={10} md={8} >

                        <Alert
                          variant="success"
                          onClose={() => setShowSuccesMessage(false)}
                          dismissible
                        >
                          {'Thank you for subscribing!'}
                        </Alert>
                      </Col>
                    </Form.Row>
                    }
                  </Modal.Body>
                </Modal>
              </Col>
            </Form.Row>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        authorImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author
        aboutAuthor
        description
      }
    }
    founderPhoto: file(relativePath: { eq: "founder/Aimee-Cruz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "logo-new.jpg" }) {
        publicURL
    }
  }
`;
